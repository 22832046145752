.single-mnd_documents {
	.nv-thumb-wrap {
		display: none;
	}

	.mnd-document-toc {
		margin: 16px 0 32px !important;
		font-size: 0.9em;
		list-style: square;

		& > li {
			margin: 0;
		}
	}

	.mnd-document-wrapper {
		position: relative;
		margin-bottom: 40px;
		padding-top: calc(100% / 5 * 7);

		& > object {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}
}

.single-mnd_webinars {
	.wp-block-embed {
		margin-bottom: 40px;
	}

	.mnd-webinar-wrapper {
		position: relative;
		margin-bottom: 40px;
		padding-top: calc(100% / 16 * 9);

		& > video {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}
}

@media only screen and (min-width: 960px) {
	.archive.tax-document,
	.post-type-archive-mnd_documents {
		.posts-wrapper > article.has-post-thumbnail .nv-ft-wrap {
			grid-template-columns: 20fr 80fr;
		}
	}
}
