/**
 * Home:
 * https://www.muneda.com/
 */
body.home .mnd-header > .innerblocks-wrap {
	justify-content: center;

	& > div {
		max-width: 1170px;
	}

	@media only screen and (max-width: 959px) {
		.mnd-header-title h2 {
			font-size: 2.75em !important;
		}
	}

	.mnd-header-solutions {
		@media only screen and (max-width: 767px) {
			margin-top: 40px !important;
			background: none;
		}

		& > .innerblocks-wrap {
			align-items: stretch;

			& > .wp-block-themeisle-blocks-advanced-column {
				display: flex;
				flex-direction: column;

				& > .wp-block-group {
					flex: 1;
					display: flex;

					& > .wp-block-group__inner-container {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						flex: 1;

						& > p {
							line-height: 1.2;
						}
					}
				}
			}
		}
	}
}

/**
 * Contact:
 * https://www.muneda.com/contact/
 */
body.mnd-contact {
	.wp-block-columns {
		.wp-block-column,
		.wp-block-group,
		.wp-block-group__inner-container {
			display: flex;
			flex-direction: column;
			flex: 1;
		}

		.wp-block-group__inner-container {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}

		.wp-block-image + .wp-block-group .wp-block-group__inner-container {
			margin-top: 16px;
			justify-content: space-between;

			& > p:last-child {
				margin-bottom: 0;
			}
		}
	}
}

/**
 * Customers:
 * https://www.muneda.com/customers/
 */
body.mnd-customers {
	.wp-block-columns {
		* {
			display: flex;
			justify-content: center;
		}

		.wp-block-image img {
			max-width: 150px;
			object-fit: contain;
		}
	}
}

/**
 * Partners:
 * https://www.muneda.com/partners/
 */
body.mnd-partners {
	h2 {
		font-size: 1.5em;
	}

	.wp-block-columns {
		* {
			display: flex;
			justify-content: center;
		}

		.wp-block-column > p {
			margin: 40px auto;
		}

		.wp-block-image img {
			max-width: 150px;
			object-fit: contain;
		}
	}
}

/**
 * References:
 * https://www.muneda.com/references/
 */
body.mnd-references {
	.wp-block-media-text__media {
		max-width: 150px;

		@media only screen and (max-width: 600px) {
			margin: 0 auto 32px;
		}
	}
}
