.um {
	&.um-account {
		.um-account-link {
			box-sizing: content-box;

			&:hover > .um-account-icon {
				color: var(--nv-secondary-accent);
			}

			& > .um-account-icon {
				padding: 0 8px !important;
				color: var(--nv-primary-accent);
				box-sizing: content-box;
			}
		}

		.um-account-heading {
			margin-bottom: 32px !important;
		}
	}

	&.um-profile {
		.um-profile-photo-img:hover {
			opacity: 1;
		}

		.um-header {
			border-bottom-color: var(--nv-primary-accent);
		}

		.um-field-mnd_um_interests {
			margin-top: 32px;
		}

		.um-field-radio.active:not(.um-field-radio-state-disabled) i {
			color: var(--nv-primary-accent) !important;
		}
	}

	&.um-register {
		.mnd-um-header-account-data,
		.mnd-um-header-profile-data {
			color: var(--nv-primary-accent);

			&::before {
				margin-right: 8px;
				vertical-align: bottom;
				font-family: dashicons;
				color: var(--nv-primary-accent);
			}
		}

		.mnd-um-header-account-data::before {
			content: '\f110';
		}

		.mnd-um-header-profile-data::before {
			content: '\f11f';
		}
	}

	a.um-link {
		color: var(--nv-primary-accent) !important;
	}

	input[type='submit'].um-button {
		background-color: var(--nv-primary-accent) !important;

		&:hover {
			background-color: var(--nv-secondary-accent) !important;
		}
	}
}
