@import '../shared/main';

@import './blocks/wp-block-button';

@import './partials/archive';
@import './partials/boxes';
@import './partials/content';
@import './partials/footer';
@import './partials/header';
@import './partials/mugm';
@import './partials/navigation';
@import './partials/pages';
@import './partials/sidebar';
@import './partials/video';

@import './plugins/embedpress';
@import './plugins/mnd-info-requests';
@import './plugins/ultimate-member';
@import './plugins/views-for-wpforms';

body {
	font-family: $font-family-normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-family-narrow;
	font-weight: 400;
}

input[type='search'] {
	box-sizing: border-box;
}

.icon-style img {
	padding: 16px;
	background: var(--nv-secondary-accent);
}
