.wp-block-columns.has-box,
.wp-block-column.has-box,
.wp-block-group.has-box,
.wp-block-media-text {
	margin-bottom: 2.5em;
	padding: 1.25em;
	box-shadow:
		0 4px 8px rgba(0, 0, 0, 0.1),
		0 1px 2px rgba(0, 0, 0, 0.2);
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

	&:hover {
		box-shadow:
			0 8px 16px rgba(0, 0, 0, 0.1),
			0 8px 8px rgba(0, 0, 0, 0.1);
	}
}
