#nv-primary-navigation-main {
	& > .menu-item-has-children > .sub-menu {
		left: 0;
		right: unset;
	}

	.sub-menu {
		background-color: $primary-accent_90;
	}
}

#nv-primary-navigation-main,
#nv-primary-navigation-sidebar {
	.sub-menu li > a {
		text-transform: none;
		justify-content: flex-start;
	}
}

#nv-primary-navigation-sidebar li {
	&:not(:last-child) {
		border-bottom: 1px solid var(--nv-light-bg);
	}

	a,
	button {
		color: var(--nv-text-color);
	}
}
