// MUGM Archive
body {
	&.post-type-archive-mnd_mugm,
	&.search {
		.nv-index-posts .type-mnd_mugm {
			.non-grid-content > .mugm-details + .nv-meta-list {
				display: none;
			}

			.mugm-details {
				font-size: 0.9em;
				display: flex;
				gap: 16px;

				label {
					font-weight: bold;
				}

				.mugm-dates {
					flex: 3;
				}

				.mugm-location {
					flex: 2;
				}
			}
		}
	}
}

// MUGM Page
body.single-mnd_mugm {
	.wp-block-themeisle-blocks-tabs__header_item[data-hash='#pdf'] > div::before,
	.wp-block-themeisle-blocks-tabs__header_item[data-hash='#video'] > div::before {
		margin-right: 4px;
		vertical-align: bottom;
		font-family: dashicons;
		font-size: 20px;
	}

	.wp-block-themeisle-blocks-tabs__header_item[data-hash='#pdf'] > div::before {
		content: '\f190' !important;
	}

	.wp-block-themeisle-blocks-tabs__header_item[data-hash='#video'] > div::before {
		content: '\f19b' !important;
	}

	.wp-block-table td {
		&.has-text-align-right {
			white-space: nowrap;
		}

		em {
			font-size: 1.5em;
		}
	}
}
