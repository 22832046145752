.wp-block-themeisle-blocks-tabs .wp-block-themeisle-blocks-tabs__header {
	margin-bottom: 16px;
	border-bottom: 2px solid var(--border-color);

	.wp-block-themeisle-blocks-tabs__header_item {
		margin-bottom: -2px !important;
		color: var(--nv-text-color) !important;
		border-bottom-width: 2px !important;

		&.active {
			background-color: $secondary-accent_20 !important;
			border-bottom-color: var(--nv-secondary-accent) !important;
		}

		&.hidden {
			display: block !important;
		}
	}
}
