.wpforms-container-full {
	&.has-dark-bg .wpforms-field-description > a {
		color: var(--nv-text-dark-bg);
		text-decoration: underline !important;
	}

	.wpforms-field-description > a {
		font-size: 16px;
	}

	button[type='submit'],
	input[type='submit'],
	.wpforms-page-button {
		color: var(--nv-text-color) !important;
		text-transform: uppercase;
		background-color: var(--nv-secondary-accent) !important;
		border: none !important;
	}
}
