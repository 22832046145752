.ep-gutenberg-content {
	margin-bottom: 30px;

	.embedpress-inner-iframe {
		width: 100%;

		.gutenberg-pdf-wraper {
			aspect-ratio: 4/5;

			.embedpress-embed-document-pdf {
				position: absolute;
				width: 100% !important;
				height: 100% !important;
				top: 0;
				left: 0;
			}

			.embedpress-el-powered {
				display: none;
			}
		}
	}
}
