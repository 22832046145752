@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
	src:
		local('Open Sans Regular'),
		local('OpenSans-Regular'),
		url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-v17-latin-regular.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */
			url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-v17-latin-regular.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-v17-latin-regular.woff')
			format('woff'),
		/* Modern Browsers */
			url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-v17-latin-regular.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-v17-latin-regular.svg#OpenSans')
			format('svg'); /* Legacy iOS */
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-v17-latin-700.eot'); /* IE9 Compat Modes */
	src:
		local('Open Sans Bold'),
		local('OpenSans-Bold'),
		url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-v17-latin-700.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */
			url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-v17-latin-700.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-v17-latin-700.woff')
			format('woff'),
		/* Modern Browsers */
			url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-v17-latin-700.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-v17-latin-700.svg#OpenSans')
			format('svg'); /* Legacy iOS */
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans Condensed';
	font-style: normal;
	font-weight: 700;
	src: url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-condensed-v15-latin-700.eot'); /* IE9 Compat Modes */
	src:
		local(''),
		url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-condensed-v15-latin-700.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */
			url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-condensed-v15-latin-700.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-condensed-v15-latin-700.woff')
			format('woff'),
		/* Modern Browsers */
			url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-condensed-v15-latin-700.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('https://www.muneda.com/wp-content/themes/neve-child/assets/fonts/open-sans-condensed-v15-latin-700.svg#OpenSansCondensed')
			format('svg'); /* Legacy iOS */
	font-display: swap;
}
