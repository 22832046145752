body.has-blog-layout-grid .nv-index-posts > .posts-wrapper {
	@media only screen and (max-width: 959px) {
		justify-content: center;
	}

	gap: 16px;

	& > .layout-grid {
		@media only screen and (max-width: 575px) {
			width: calc(100% - 32px);
		}

		@media only screen and (min-width: 576px) and (max-width: 959px) {
			width: calc((100% - 48px) / 2);
		}

		@media only screen and (min-width: 960px) {
			width: calc((100% - 32px) / 3);
		}

		margin-bottom: 0;
		padding: 16px;

		.content {
			flex: 1;

			.wp-post-image {
				aspect-ratio: 16/9;
				object-fit: contain;
			}

			.entry-title {
				height: calc(var(--lineheight, var(--h3lineheight)) * 5);
				font-size: 1.2em;
				display: -webkit-box;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}

			.nv-meta-list > .author {
				display: none;
			}
		}
	}
}

.nv-index-posts {
	.nv-non-grid-article,
	.layout-grid {
		padding: 1.25em;
		transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

		.nv-tags-list a {
			color: var(--nv-primary-accent) !important;
			background-color: $primary-accent_10;
		}
	}

	.layout-grid {
		margin-bottom: 0;
		box-shadow:
			0 2px 4px rgba(0, 0, 0, 0.1),
			0 1px 2px rgba(0, 0, 0, 0.2);

		&:hover {
			box-shadow:
				0 4px 8px rgba(0, 0, 0, 0.1),
				0 4px 4px rgba(0, 0, 0, 0.1);
		}
	}

	.nv-non-grid-article {
		margin-bottom: 2.5em;
		box-shadow:
			0 4px 8px rgba(0, 0, 0, 0.1),
			0 1px 2px rgba(0, 0, 0, 0.2);

		&:hover {
			box-shadow:
				0 8px 16px rgba(0, 0, 0, 0.1),
				0 8px 8px rgba(0, 0, 0, 0.1);
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.article-content-col {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.excerpt-wrap {
			font-size: 0.9em;
		}
	}
}
