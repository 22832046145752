.entry-header {
	text-align: left !important;
}

.nv-content-wrap {
	a {
		&:not(.wp-block-button__link):hover {
			color: var(--nv-primary-accent);
		}

		&:not([class]) {
			text-decoration: none !important;
		}

		&[href^='http'],
		&[href^='mailto'],
		&[href$='.pdf'] {
			&::before {
				margin-right: 4px;
				vertical-align: bottom;
				font-family: dashicons;
				font-size: 20px;
			}
		}

		&[href^='http']::before {
			content: '\f504';
		}

		&[href^="https://maps.app.goo.gl"]::before
		{
			content: '\f230';
		}

		&[href^='mailto']::before {
			content: '\f465';
		}

		&[href*='youtube.com']::before {
			content: '\f19b';
		}

		&[href$='.pdf']::before {
			content: '\f190';
		}

		&[href$='.ppt'],
		&[href$='.pptx'] {
			&::before {
				content: '\f497';
			}
		}

		&[href*='localhost'],
		&[href*='muneda.com'] {
			&:not([href^='mailto']):not([href$='.pdf']):not([href$='.ppt']):not([href$='.pptx'])::before {
				display: none;
			}
		}
	}

	.wp-block-image,
	.wp-block-media-text__media {
		a::before {
			display: none;
		}
	}

	.no-wrap {
		white-space: nowrap;
	}

	.muneda-webinar {
		margin-bottom: 32px;
	}

	.wp-block-button__link:hover {
		opacity: 0.8;
	}

	.post-password-form {
		input[type='password'],
		input[type='submit'] {
			height: 40px;
		}

		input[type='submit'] {
			padding: 8px 32px;
		}
	}
}

.nv-single-page-wrap > .post-pages-links {
	margin: 40px 0;
	font-size: var(--wp--preset--font-size--medium);
}
