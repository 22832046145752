body.single-mnd_videos {
	.nv-sidebar-wrap.nv-right,
	.nv-thumb-wrap {
		display: none;
	}

	.nv-single-post-wrap {
		max-width: 100% !important;
	}

	.wp-block-embed {
		margin-bottom: 1.75em;
		box-shadow:
			0 4px 8px rgba(0, 0, 0, 0.1),
			0 1px 2px rgba(0, 0, 0, 0.2);
		transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

		&:hover {
			box-shadow:
				0 8px 16px rgba(0, 0, 0, 0.1),
				0 8px 8px rgba(0, 0, 0, 0.1);
		}
	}

	h2:first-of-type::after {
		font-size: 0.5em;
		content: '(AI generated)';
		line-height: 1;
		display: block;
	}
}
