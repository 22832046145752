.nv-sidebar-wrap {
	.widget .widget-title {
		margin-bottom: 16px !important;
		font-family: var(--headingsfontfamily);
		font-size: 1.5em;
	}

	.widget_recent_entries li:not(:last-child) {
		padding-bottom: 10px;
		border-bottom: 1px solid var(--nv-light-bg);
	}
}
